//App Custom Header
.webiny-pb-section-header {
  background-color: rgb(0,0,0);
  text-color: rgb(255, 255, 255);
}

.webiny-pb-section-footer {
  background-color: rgb(0, 0, 0);
  text-color: rgb(255, 255, 255);
}

@include desktop-and-tablet{
  .webiny-pb-section-header {

  	&__navigation {
  		ul {
  			li {
  				padding-left: 0px;
  				padding-right: 0px;
  			}
  		}
  	}
  }
}
@include mobile{
  .webiny-pb-section-header {
    &__mobile-icon{
    	div{
    		span{
    			background: white none repeat scroll 0% 0% !important;
    		}
    	}
    }
  }
}